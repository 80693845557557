import SpinnerOverlay from 'components/spinner/SpinnerOverlay';
import React, { lazy, Suspense, useEffect } from 'react';
import {
    Route,
    Routes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

const Home = lazy(() => import('pages/home/Home'));
const WhatWeDo = lazy(() => import('pages/what-we-do/WhatWeDo'));
const Projects = lazy(() => import('pages/projects/Projects'));
const ContactUs = lazy(() => import('pages/contact-us/ContactUs'));
const Blogs = lazy(() => import('pages/blogs/Blogs'));

function AppRoutes() {
    const { pathname } = useLocation();
    const navigationType = useNavigationType();

    useEffect(() => {
        if (navigationType !== 'POP') {
            window.scrollTo(0, 0);
        }
    }, [pathname, navigationType]);

    return (
        <Suspense fallback={<SpinnerOverlay open onClose={() => ''} />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/what-we-do" element={<WhatWeDo />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/blogs" element={<Blogs />} />
            </Routes>
        </Suspense>
    );
}

export default AppRoutes;
