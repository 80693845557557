import { Dialog } from '@headlessui/react';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from 'assets/logo-light.svg';
import Button from 'components/button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import MenuItem from 'components/nav/MenuItem';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

interface MenuProps {
    value: string;
    active: boolean;
    path: string;
}

function NavModal({ isOpen, onClose }: ModalProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const [menu, setMenu] = useState([
        {
            value: 'Home',
            active: location.pathname === '/',
            path: '/',
        },
        {
            value: 'What We Do',
            active: location.pathname === '/what-we-do',
            path: '/what-we-do',
        },
        {
            value: 'Projects',
            active: location.pathname === '/projects',
            path: '/projects',
        },
    ]);

    function handleMenuClick(item: MenuProps) {
        setMenu(
            menu.map((m) =>
                m.value === item.value
                    ? { ...m, active: true }
                    : { ...m, active: false },
            ),
        );
        navigate(item.path);
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className="relative z-50"
            as="div"
        >
            <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
            <motion.div className="fixed inset-0 lg:top-0 overflow-y-hidden">
                <div className="flex items-center justify-center h-full">
                    <div
                        className="
                            rounded-[40px] bg-[url('assets/hero-bg.svg')] remove-outline
                            w-[900px] lg:w-full lg:rounded-[0px] lg:h-full relative
                        "
                    >
                        <div className="flex items-center justify-between bg-primary py-[20px] px-[40px] lg:px-[20px]">
                            <Logo className="mr-[100px] cursor-pointer lg:w-[150px]" />
                            <CloseIcon className="w-[25px]" onClick={onClose} />
                        </div>
                        <div className="flex flex-col p-[40px] lg:bg-[url('assets/hero-bg.svg')] h-full z-[10]">
                            <li className="self-start list-none uppercase [&_li]:text-[18px] [&_li]:self-start [&_p]:self-start [&_div]:mb-[20px]">
                                {menu.map((item) => (
                                    <MenuItem
                                        key={item.path}
                                        value={item.value}
                                        active={item.active}
                                        onClick={() => handleMenuClick(item)}
                                    />
                                ))}
                            </li>
                            <div>
                                <Button
                                    onClick={() => {
                                        navigate('/contact-us');
                                        onClose();
                                    }}
                                    text="Get In Touch"
                                    textColor="text-white"
                                    border="border border-white"
                                />
                            </div>
                        </div>
                        <div className="absolute w-full bg-gradient-to-b from-[#ff000000] rounded-b-[40px] to-primary z-[1] pointer-events-none h-[100%] bottom-0" />
                    </div>
                </div>
            </motion.div>
        </Dialog>
    );
}

export default NavModal;
