import { motion } from 'framer-motion';

interface MenuItemProps {
    value: string;
    active: boolean;
    onClick: () => void;
}

function MenuItem({ value, active, onClick }: MenuItemProps) {
    return (
        <motion.div
            aria-hidden="true"
            whileHover="hover"
            onClick={onClick}
            className={`
                ${
                    active
                        ? 'text-secondary [&_p]:visible [&_p]:w-[26px]'
                        : 'text-white [&_p]:invisible'
                }
                mr-[40px] cursor-pointer flex flex-col items-center text-[18px] hover:text-secondary [&_p]:hover:visible
            `}
        >
            <li>{value}</li>
            <motion.p
                className="border mt-1 underline-custom"
                variants={{
                    hover: {
                        paddingLeft: 12,
                        paddingRight: 12,
                        transition: { duration: 0.2 },
                    },
                }}
            />
        </motion.div>
    );
}

export default MenuItem;
