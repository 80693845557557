import AppRoutes from 'routes/Routes';
import NavigationBar from 'components/nav/NavigationBar';
import { useState } from 'react';
import NavModal from 'components/modal/NavModal';
import ContactModal from 'components/modal/ContactModal';
import SpinnerOverlay from 'components/spinner/SpinnerOverlay';
import ModalStatus from 'components/modal/ModalStatus';
import sendEmail from 'utils/helpers';

function App() {
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showModalStatus, setShowModalStatus] = useState({
        status: 'success',
        show: false,
    });

    async function onSubmit(values: any) {
        setIsOpen(false);
        setShowSpinner(true);
        try {
            await sendEmail(values);
            setShowSpinner(false);
            setShowModalStatus({ status: 'success', show: true });
        } catch (error) {
            setShowSpinner(false);
            setShowModalStatus({ status: 'failed', show: true });
        }
    }

    return (
        <div>
            <NavModal isOpen={open} onClose={() => setOpen(false)} />
            <ContactModal
                onSubmit={onSubmit}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            />
            <SpinnerOverlay
                open={showSpinner}
                onClose={() => setShowSpinner(false)}
            />
            <ModalStatus
                status="success"
                isOpen={showModalStatus.show}
                onClose={() =>
                    setShowModalStatus({ status: 'success', show: false })
                }
            />
            <NavigationBar
                open={open}
                setOpen={setOpen}
                onGetInTouch={() => setIsOpen(true)}
            />
            <AppRoutes />
        </div>
    );
}

export default App;
