function Input({
    field,
    form: { touched, errors },
    label,
    isTextArea = false,
    name,
    id,
    ...props
}: {
    field?: any;
    form?: any;
    label: string;
    isTextArea: boolean;
    name: string;
    id: string;
}) {
    return (
        <div>
            <label
                htmlFor="message"
                className={`${
                    touched[field.name] && errors[field.name]
                        ? 'text-error'
                        : ''
                }`}
            >
                {label}
            </label>
            {isTextArea ? (
                <textarea
                    id={id}
                    name={name}
                    className={`${
                        touched[field.name] && errors[field.name]
                            ? 'border-error'
                            : 'border-white'
                    } w-full border h-[200px] border-solid rounded-[9px] p-[12px] bg-transparent outline-0 text-white align-start mt-[12px]`}
                    {...field}
                    {...props}
                />
            ) : (
                <input
                    id={id}
                    name={name}
                    className={`${
                        touched[field.name] && errors[field.name]
                            ? 'border-error'
                            : 'border-white'
                    } w-full border border-solid rounded-[9px] p-[12px] bg-transparent outline-0 text-white mt-[12px]`}
                    {...field}
                    {...props}
                />
            )}
            {touched[field.name] && errors[field.name] && (
                <div className="text-error">{errors[field.name]}</div>
            )}
        </div>
    );
}

export default Input;
