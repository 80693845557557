const sendEmail = async (values: {
    email: string;
    fullName: string;
    phoneNumber: string;
    company: string;
    message: string;
}) => {
    const res = fetch(
        'https://talkthetalk.a2hosted.com/maven/wp-json/wp/v2/send-email',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                personalizations: [
                    {
                        to: [
                            {
                                email: 'scotthunter58+sh5or9q3eqgywxezn8tb@boards.trello.com',
                            },
                        ],
                        subject: `Email from: ${values.email}`,
                    },
                ],
                from: {
                    email: 'zee@mavensoftwaredevelopment.com',
                },
                content: [
                    {
                        type: 'text/html',
                        value: `<div>
                                <p>Name: ${values.fullName}</p>
                                <p>Email: ${values.email}</p>
                                <p>Phone: ${values.phoneNumber}</p>
                                <p>Company: ${values.company}</p>
                                <p>Message: ${values.message}</p>
                            </div>`,
                    },
                ],
            }),
        },
    );
    return res;
};

export default sendEmail;
