import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, Transition } from 'framer-motion';
import { ReactComponent as Logo } from 'assets/logo-light.svg';
import Button from 'components/button/Button';

interface MenuItemProps {
    value: string;
    active: boolean;
    onClick: () => void;
}

interface MenuProps {
    value: string;
    active: boolean;
    path: string;
}

interface MenuIconProps {
    isOpen?: boolean;
    color?: string;
    strokeWidth?: string | number;
    transition?: Transition;
    lineProps?: any;
    width?: number;
    height?: number;
    onClick?: any;
}

function MenuIcon({
    isOpen = false,
    width = 20,
    height = 20,
    strokeWidth = 2,
    color = '#FFF',
    ...props
}: MenuIconProps) {
    const variant = isOpen ? 'opened' : 'closed';
    const top = {
        closed: {
            rotate: 0,
            translateY: 0,
        },
        opened: {
            rotate: 45,
            translateY: 2,
        },
    };
    const center = {
        closed: {
            opacity: 1,
        },
        opened: {
            opacity: 0,
        },
    };
    const bottom = {
        closed: {
            rotate: 0,
            translateY: 0,
        },
        opened: {
            rotate: -45,
            translateY: -2,
        },
    };

    const lineProps = {
        stroke: color,
        strokeWidth: strokeWidth as number,
        vectorEffect: 'non-scaling-stroke',
        initial: 'closed',
        animate: variant,
    };

    const unitHeight = 6;
    const unitWidth = (unitHeight * (width as number)) / (height as number);

    return (
        <motion.svg
            viewBox={`0 0 ${unitWidth} ${unitHeight}`}
            overflow="visible"
            preserveAspectRatio="none"
            width={width}
            height={height}
            {...props}
        >
            <motion.line
                x1="0"
                x2={unitWidth}
                y1="0"
                y2="0"
                variants={top}
                {...lineProps}
            />
            <motion.line
                x1="0"
                x2={unitWidth}
                y1="2"
                y2="2"
                variants={center}
                {...lineProps}
            />
            <motion.line
                x1="0"
                x2={unitWidth}
                y1="4"
                y2="4"
                variants={bottom}
                {...lineProps}
            />
        </motion.svg>
    );
}

function MenuItem({ value, active, onClick }: MenuItemProps) {
    return (
        <motion.div
            aria-hidden="true"
            whileHover="hover"
            onClick={onClick}
            className={`
                ${
                    active
                        ? 'text-secondary [&_p]:visible [&_p]:w-[26px]'
                        : 'text-white [&_p]:invisible'
                }
                mr-[40px] cursor-pointer flex flex-col items-center text-[18px] hover:text-secondary [&_p]:hover:visible
            `}
        >
            <li>{value}</li>
            <motion.p
                className="border mt-1 underline-custom"
                variants={{
                    hover: {
                        paddingLeft: 12,
                        paddingRight: 12,
                        transition: { duration: 0.2 },
                    },
                }}
            />
        </motion.div>
    );
}

function NavigationBar({
    open,
    setOpen,
    onGetInTouch,
}: {
    open: boolean;
    setOpen: any;
    onGetInTouch: () => any;
}) {
    const navigate = useNavigate();
    const location = useLocation();

    const [menu, setMenu] = useState([
        {
            value: 'Home',
            active: location.pathname === '/',
            path: '/',
        },
        {
            value: 'What We Do',
            active: location.pathname === '/what-we-do',
            path: '/what-we-do',
        },
        {
            value: 'Projects',
            active: location.pathname === '/projects',
            path: '/projects',
        },
        {
            value: 'Blogs',
            active: location.pathname === '/blogs',
            path: '/blogs',
        },
    ]);

    function handleMenuClick(item: MenuProps) {
        setMenu(
            menu.map((m) =>
                m.value === item.value
                    ? { ...m, active: true }
                    : { ...m, active: false },
            ),
        );
        navigate(item.path);
    }

    function handleLogoClick() {
        navigate('/');
        handleMenuClick(menu[0]);
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open]);

    useEffect(() => {
        setMenu((m) =>
            m.map((item) =>
                item.path === location.pathname
                    ? { ...item, active: true }
                    : { ...item, active: false },
            ),
        );
    }, [location]);

    return (
        <nav className="flex items-center justify-between bg-primary py-[20px] px-[40px] lg:px-[20px] sticky top-0 z-[12]">
            <div className="flex items-center">
                <Logo
                    onClick={handleLogoClick}
                    className="mr-[100px] cursor-pointer lg:w-[150px]"
                />
                <ul className="list-none flex items-center uppercase text-[18px] uppercase lg:hidden">
                    {menu.map((item) => (
                        <MenuItem
                            key={item.path}
                            value={item.value}
                            active={item.active}
                            onClick={() => handleMenuClick(item)}
                        />
                    ))}
                </ul>
            </div>
            <div className="hidden lg:flex items-center">
                <div className="hidden lg:block ml-[20px] mt-[5px]">
                    <MenuIcon isOpen={open} onClick={() => setOpen(!open)} />
                </div>
            </div>
            <div className="block lg:hidden">
                <Button
                    onClick={onGetInTouch}
                    text="Get In Touch"
                    textColor="text-white"
                    border="border border-white"
                />
            </div>
        </nav>
    );
}

export default NavigationBar;
