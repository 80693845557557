import {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useEffect,
    useState,
} from 'react';

type AppProps = {
    token: string;
    reviews: any;
    isReviewsLoading: boolean;
    setToken?: Dispatch<SetStateAction<string>>;
    setReviews?: any;
    setIsReviewsLoading?: (_: boolean) => void;
    posts: any[];
    setPosts?: any;
    isPostsLoading: boolean;
    setIsPostsLoading?: (_: boolean) => void;
};

export const AppContext = createContext<AppProps>({
    token: '',
    reviews: [],
    posts: [],
    isReviewsLoading: false,
    isPostsLoading: false,
});

const getToken = async () => {
    const res = await fetch(
        `https://talkthetalk.a2hosted.com/maven/wp-json/jwt-auth/v1/token?username=${process.env.REACT_APP_WORDPRESS_USER}&password=${process.env.REACT_APP_WORDPRESS_PASS}`,
        { method: 'POST' },
    );
    const data = await res.json();
    localStorage.setItem('token', data.token);
};

const getReviews = async () => {
    try {
        const token = localStorage.getItem('token');
        const res = await fetch(
            'https://talkthetalk.a2hosted.com/maven/wp-json/site-reviews/v1/reviews',
            { headers: { Authorization: `Bearer ${token}` }, method: 'GET' },
        );
        const reviews = await res.json();
        return reviews;
    } catch {
        return null;
    }
};

const getPosts = async () => {
    try {
        const token = localStorage.getItem('token');
        const res = await fetch(
            'https://talkthetalk.a2hosted.com/maven/wp-json/wp/v2/posts',
            { headers: { Authorization: `Bearer ${token}` }, method: 'GET' },
        );
        const posts: any = await res.json();
        posts.forEach((item: any) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(
                item?.content?.rendered,
                'text/html',
            );
            const paragraphs = doc.querySelectorAll('p');
            paragraphs.forEach((p) => {
                const content = p.textContent;
                console.log(content);
            });
        });
        const data = posts.map((item: any) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(
                item?.content?.rendered,
                'text/html',
            );
            const paragraphs = doc.querySelectorAll('p');
            let content: any = [];
            paragraphs.forEach((p) => {
                content = [...content, p.textContent];
            });

            return {
                id: item?.id,
                title: item?.title?.rendered,
                content,
                date: item?.date,
            };
        });
        return data;
    } catch {
        return null;
    }
};

function isHalfDayPassed() {
    const lastCallTimestamp = localStorage.getItem('lastCallTimestamp');
    if (!lastCallTimestamp) {
        // If the function has never been called before, allow the call.
        return true;
    }

    const halfDayInMilliseconds = 12 * 60 * 60 * 1000; // Half a day in milliseconds
    const currentTime = new Date().getTime();
    return (
        currentTime - parseInt(lastCallTimestamp, 10) >= halfDayInMilliseconds
    );
}

function AppProvider({ children }: { children: ReactNode }) {
    const [token, setToken] = useState('');
    const [reviews, setReviews] = useState([]);
    const [posts, setPosts] = useState([]);
    const [isReviewsLoading, setIsReviewsLoading] = useState(false);
    const [isPostsLoading, setIsPostsLoading] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setIsReviewsLoading(true);
            if (isHalfDayPassed()) {
                const currentTime = new Date().getTime();
                await getToken();
                localStorage.setItem(
                    'lastCallTimestamp',
                    currentTime.toString(),
                );
                console.log('ehllo');
            }
            const reviewsData = await getReviews();
            const postsData = await getPosts();
            if (reviewsData) {
                setReviews(reviewsData);
                setIsReviewsLoading(false);
            }
            if (postsData) {
                console.log(postsData);
                setPosts(postsData);
                setIsPostsLoading(false);
            }
        };
        loadData();
        return () => {
            loadData();
        };
    }, []);

    return (
        <AppContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                token,
                setToken,
                reviews,
                setReviews,
                isReviewsLoading,
                setIsReviewsLoading,
                posts,
                setPosts,
                isPostsLoading,
                setIsPostsLoading,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export default AppProvider;
