import { Dialog } from '@headlessui/react';
import Button from 'components/button/Button';
import { AnimatePresence, motion } from 'framer-motion';

type ModalProps = {
    status: string;
    isOpen: boolean;
    onClose: () => void;
};

function ModalStatus({ status = 'success', isOpen, onClose }: ModalProps) {
    return (
        <AnimatePresence>
            {isOpen && (
                <Dialog
                    open={isOpen}
                    onClose={onClose}
                    className="relative z-50"
                    as="div"
                >
                    <div
                        className="fixed inset-0 bg-black/70"
                        aria-hidden="true"
                    />
                    <motion.div
                        className="fixed inset-0 lg:top-0 overflow-y-auto"
                        initial={{
                            opacity: 0,
                            scale: 0.75,
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                ease: 'easeOut',
                                duration: 0.15,
                            },
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0.75,
                            transition: {
                                ease: 'easeIn',
                                duration: 0.15,
                            },
                        }}
                    >
                        <div className="flex items-center justify-center h-full">
                            <div
                                className="
                                    rounded-[40px] bg-white remove-outline px-[100px]
                                    w-auto lg:w-full lg:rounded-[0px] lg:h-full relative
                                "
                            >
                                <div className="flex flex-col p-[40px]">
                                    <h1
                                        className={`${
                                            status === 'success'
                                                ? 'text-secondary'
                                                : 'text-error'
                                        } text-[50px] lg:text-[40px] leading-[3.5rem] md:leading-[3rem] mb-[40px] text-center font-bold font-semibold`}
                                    >
                                        {status === 'success'
                                            ? 'Successful'
                                            : 'Failed'}
                                    </h1>
                                    <p
                                        className="
                                            text-[18px] lg:text-[16px] leading-[2rem] text-primary text-center font-light
                                            font-[Poppins] tracking-[.10em] mb-[40px]
                                        "
                                    >
                                        {status === 'success'
                                            ? 'Your message was sent successfully! Thank you for your time.'
                                            : 'Message sending failed!'}
                                    </p>
                                    <div className="self-center">
                                        <Button
                                            onClick={onClose}
                                            text="Okay"
                                            textColor="text-white"
                                            backgroundColor={
                                                status === 'success'
                                                    ? 'bg-secondary'
                                                    : 'bg-error'
                                            }
                                            iconColor="fill-white"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </Dialog>
            )}
        </AnimatePresence>
    );
}

export default ModalStatus;
