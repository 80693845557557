import { Dialog } from '@headlessui/react';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import ContactUs from 'components/forms/ContactUs';
import { AnimatePresence, motion } from 'framer-motion';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: any) => void;
};

function ContactModal({ isOpen, onClose, onSubmit }: ModalProps) {
    return (
        <AnimatePresence>
            {isOpen && (
                <Dialog
                    open={isOpen}
                    onClose={onClose}
                    className="relative z-50"
                    as="div"
                >
                    <div
                        className="fixed inset-0 bg-black/70"
                        aria-hidden="true"
                    />
                    <motion.div
                        className="fixed inset-0 lg:top-0 overflow-y-auto"
                        initial={{
                            opacity: 0,
                            scale: 0.75,
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            transition: {
                                ease: 'easeOut',
                                duration: 0.15,
                            },
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0.75,
                            transition: {
                                ease: 'easeIn',
                                duration: 0.15,
                            },
                        }}
                    >
                        <div className="flex items-center justify-center h-full">
                            <div
                                className="
                                    rounded-[40px] bg-[url('assets/hero-bg.svg')] remove-outline
                                    w-[900px] lg:w-full lg:rounded-[0px] lg:h-full relative
                                "
                            >
                                <div className="flex flex-col p-[40px] lg:bg-[url('assets/hero-bg.svg')]">
                                    <div className="self-end mb-[40px]">
                                        <CloseIcon
                                            onClick={onClose}
                                            className="cursor-pointer"
                                        />
                                    </div>
                                    <h1 className="text-[50px] lg:text-[40px] leading-[3.5rem] md:leading-[3rem] mb-[40px] text-center font-bold text-white">
                                        How can we help?
                                    </h1>
                                    <p
                                        className="
                                            text-[18px] lg:text-[16px] leading-[2rem] text-light text-center font-light
                                            font-[Poppins] tracking-[.10em] mb-[40px]
                                        "
                                    >
                                        Send us a message and our team will get
                                        right back to you as soon as possible!
                                    </p>
                                    <ContactUs onSubmit={onSubmit} />
                                </div>
                                <div className="absolute w-full bg-gradient-to-b from-[#ff000000] rounded-b-[40px] to-primary z-[0] pointer-events-none h-[100%] bottom-0" />
                            </div>
                        </div>
                    </motion.div>
                </Dialog>
            )}
        </AnimatePresence>
    );
}

export default ContactModal;
