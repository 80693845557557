import { ReactComponent as UploadIcon } from 'assets/upload-icon.svg';
import { ReactComponent as ChevronIcon } from 'assets/hero/chevron.svg';

interface ButtonProps {
    onClick?: () => void;
    text: string;
    textColor?: string;
    backgroundColor?: string;
    showIcon?: boolean;
    shadow?: string;
    border?: string;
    iconColor?: string;
    rounded?: string;
    disabled?: boolean;
    large?: boolean;
    showChevron?: boolean;
}

function Button({
    onClick,
    text,
    textColor = 'text-white',
    backgroundColor,
    showIcon = false,
    shadow,
    border,
    iconColor = 'fill-white',
    rounded = 'rounded-[6px]',
    disabled = false,
    large = false,
    showChevron = false,
}: ButtonProps) {
    return (
        <button
            type="submit"
            disabled={disabled}
            onClick={onClick}
            className={`
                ${border} ${textColor} ${backgroundColor} ${shadow} ${rounded}
                ${
                    (!border && !backgroundColor) || disabled
                        ? `pl-0 ${disabled ? 'pl-4' : ''}`
                        : 'hover:shadow-[0px_8px_33px_rgba(0,162,255,0.21)]'
                } font-[Poppins] outline-none
                ${
                    large
                        ? 'min-w-[194px] sm:w-full w-auto px-[20px] sm:h-[42px] h-[66px]'
                        : 'px-[18px] py-[14px] font-bold'
                } font-weight-[600] text-[18px] flex items-center justify-center`}
        >
            {text}
            {showIcon && (
                <UploadIcon className={`${iconColor} cursor-pointer ml-4`} />
            )}
            {showChevron && (
                <ChevronIcon
                    className={`scale-100 ${iconColor} stroke-2 mx-3`}
                />
            )}
        </button>
    );
}

export default Button;
