import { Dialog } from '@headlessui/react';

interface SpinnerProps {
    open: boolean;
    onClose: () => void;
}

function SpinnerOverlay({ open, onClose }: SpinnerProps) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                className="relative z-50"
                as="div"
            >
                <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    {/* <Dialog.Panel className="mx-auto max-w-sm rounded">
                        <Logo
                            className="animate-spin"
                            width="100px"
                            height="100px"
                        />
                    </Dialog.Panel> */}
                    <span className="flex h-[100px] w-[100px]">
                        <span className="animate-ping absolute inline-flex h-[100px] w-[100px] rounded-full bg-secondary opacity-75" />
                        <span className="relative inline-flex rounded-full h-[100px] w-[100px] bg-secondary" />
                    </span>
                </div>
            </Dialog>
        </div>
    );
}

export default SpinnerOverlay;
