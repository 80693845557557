import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { createRef, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface FormValues {
    fullName: string;
    email: string;
    company: string;
    message: string;
}

// Declare GTM dataLayer array.
declare global {
    interface Window {
        dataLayer: any[];
    }
}

const schema = yup.object().shape({
    fullName: yup.string().required('* this is a required field'),
    email: yup
        .string()
        .email('Invalid email')
        .required('* this is a required field'),
    message: yup.string().required('* this is a required field'),
});

function normalizeInput(value: string, previousValue: any) {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;
        if (cvLength < 7)
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
            3,
            6,
        )}-${currentValue.slice(6, 10)}`;
    }

    return value;
}

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

function ContactUs({ onSubmit }: { onSubmit: (values: any) => void }) {
    const initialValues: FormValues = {
        fullName: '',
        email: '',
        company: '',
        message: '',
    };

    const recaptchaRef: any = createRef();

    const [phone, setPhone] = useState('');
    const [captchaToken, setCaptchaToken] = useState('');
    const [isCaptchaTriggered, setIsCaptchaTriggered] = useState(false);

    const prevPhone = usePrevious(phone);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values, actions) => {
                if (captchaToken) {
                    onSubmit({
                        fullName: values.fullName,
                        email: values.email,
                        phoneNumber: phone,
                        company: values.company,
                        message: values.message,
                    });
                    actions.resetForm();
                    setPhone('');
                    setIsCaptchaTriggered(false);
                    setCaptchaToken('');
                    recaptchaRef.current.reset();
                    window.dataLayer.push({ event: 'contact_form_submitted' });
                } else {
                    setIsCaptchaTriggered(true);
                }
            }}
        >
            {({ setFieldValue, values }) => (
                <Form className="w-full grid grid-cols-2 md:grid-cols-1 xs:grid-cols-1 gap-4 z-[1]">
                    <div className="w-full text-white font-[Poppins] font-normal">
                        <Field
                            id="fullName"
                            name="fullName"
                            label="Full Name*"
                            component={Input}
                            value={values.fullName}
                            onChange={(evt: any) => {
                                const { value } = evt.target;
                                setFieldValue(
                                    'fullName',
                                    value.replace(/(^\w|\s\w)/g, (m: string) =>
                                        m.toUpperCase(),
                                    ),
                                );
                            }}
                        />
                    </div>
                    <div className="w-full text-white font-[Poppins] font-normal">
                        <Field
                            id="email"
                            name="email"
                            label="Email*"
                            component={Input}
                        />
                    </div>
                    <div className="w-full text-white font-[Poppins] font-normal">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            className="w-full border border-solid rounded-[9px] p-[12px] bg-transparent outline-0 text-white mt-[12px]"
                            value={phone}
                            onChange={(evt) =>
                                setPhone(
                                    normalizeInput(evt.target.value, prevPhone),
                                )
                            }
                        />
                    </div>
                    <div className="w-full text-white font-[Poppins] font-normal">
                        <Field
                            id="company"
                            name="company"
                            label="Company"
                            component={Input}
                            value={values.company}
                            onChange={(evt: any) => {
                                const { value } = evt.target;
                                setFieldValue(
                                    'company',
                                    value.replace(/(^\w|\s\w)/g, (m: string) =>
                                        m.toUpperCase(),
                                    ),
                                );
                            }}
                        />
                    </div>
                    <div className="w-full text-white font-[Poppins] font-normal col-span-2 md:col-span-1">
                        <Field
                            id="message"
                            name="message"
                            label="Message*"
                            isTextArea
                            component={Input}
                            value={values.message}
                            onChange={(evt: any) => {
                                const { value } = evt.target;
                                setFieldValue(
                                    'message',
                                    value.charAt(0).toUpperCase() +
                                        value.slice(1),
                                );
                            }}
                        />
                    </div>
                    <div className="w-full text-white font-[Poppins] font-normal col-span-2 md:col-span-1">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={
                                process.env.REACT_APP_CAPTCHA_SITE_KEY ?? ''
                            }
                            onChange={(token: any) => {
                                setCaptchaToken(token);
                                setIsCaptchaTriggered(true);
                            }}
                        />
                        {!captchaToken && isCaptchaTriggered && (
                            <div className="text-error">
                                Verify your identity first
                            </div>
                        )}
                    </div>
                    <div>
                        <Button
                            text="Send Message"
                            textColor="text-white"
                            backgroundColor="bg-secondary"
                            showIcon
                            iconColor="fill-white"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default ContactUs;
